/* eslint jsx-a11y/label-has-for:0 */

import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout, Wrapper, Header } from '../components'

import config from '../../config'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
`

const Resume = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`Resume | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Content>
        <div id="wrapper">
          <div id="face">
            <h1 id="bigname">Sheree&nbsp;Pe&ntilde;a</h1>
          </div>
          <p>
            <em>
              Tenacious problem solver with the ability to see around corners and dive deep into technical issues.
              Focused on delivery and risk mitigation through nimble project leadership, rigorous software testing and,
              quality culture setting.
            </em>
          </p>
          <h2>Critical Skills</h2>
          <ul>
            <li>Highly organized self-starter never caught unprepared.</li>
            <li>Information radiator, maintaining insight amidst complexity.</li>
            <li>Unflappable in high stress, deadline-oriented environments.</li>
            <li>Knack for the details, without losing sight of the big picture.</li>
            <li>Resilient in the face of confusion, unthwarted by ambiguity.</li>
          </ul>
          <h2>Career History</h2>

          <h3>Nice Catch! &bull; [Remote] &bull; 2012&mdash;Current</h3>

          <p>
            <strong>Owner, Principal:</strong> Provide consulting services for software quality processes and test
            strategy in freelance/contractor capacity for clientele on a ongoing project basis. Partner with freelance
            designers to bring technical guidance to front-end web development projects for independent businesses using
            HTML/CSS, WordPress, Vue.js, React, Gatsby, Nuxt, and Squarespace. Provided WCAG 2.1 AA reviews for boutique
            websites. Wrote test automation frameworks &amp; test harnesses and configured development pipelines.
          </p>

          <h3>Sketch &bull; [Remote] &bull; 2019&mdash;2021</h3>
          <p>
            <strong>Test &amp; Quality:</strong>
            Held titles of QA Principal, Cloud Test Lead, Quality Assurance Engineer. Performed as software tester,
            hiring team, technical documentation, team supervision. Focused heavily on testing of back end web services.
          </p>

          <h3>Foundry Interactive &bull; Seattle, WA &bull; 2016&mdash;2019</h3>
          <p>
            <strong>Quality/Test Director:</strong> People management and cross-functional team leadership. Requirements
            analysis, management, and writing. User story and acceptance criteria writing. Create and manage dev and
            test environments. Technical and project documentation and training materials. Code reviews and git-flow
            policies. Test and development planning documentation. IT equipment and test lab management. Test coverage
            strategy, planning, and test matrices. CI/CD build server management. Running bug bashes and hack-a-thon
            style kickoffs. Test coverage strategy. Internal accessibility and security champion.
          </p>

          <p>
            <strong>Recruiting & HR:</strong> Onboarding process and implementation. Offboarding and dismissals.
            Recruiting, hiring, retention. Documentation. Interviewing, triaging. Negotiations. Technical and project
            documentation and training materials. Resource planning and staff scheduling. Recruitment and hiring for
            contractor-based staffing model.
          </p>

          <p>
            <strong>Front End Web Development:</strong> Wrote Mocha/Chai automated integration tests against client
            APIs. APIs. Built Node.js mock servers for quality assurance testing and rapid development. Spun up
            Cypress.io front-end UI test suites providing integration and end to end test coverage for React-based
            websites. Remediated front end UI changes in multiple platforms across legacy systems in JSPX, Angular,
            Asp.net. UI and UX design and development of marketing website, utilizing HTML/CSS/JS/Vue.js in
            component-based system. Performed code reviews and static analysis in cross-functional teams. Costed out
            technical solutions for business development team. Mentored team of junior developers. Ran lunch-and-learns
            info sessions and managed learning channel for team-wide edification. Cross-trained team in test automation
            best practices. Lead documentarian, creating project and program wikis and establishing culture of capture.{' '}
          </p>

          <p>
            <strong>Client Services & PMO:</strong> Project management tool selection and configuration. Create and
            enforce development and project process. Scope management and logging. Reporting and report data management.
            Daily triage, backlog grooming, sprint planning. Meeting facilitation and calendar management. Budget and
            utilization reporting. Sensemaking exploratory research for discovery and intake phases. Work breakdown
            schedules, estimates, and mapping of the critical path. Marketing collateral, presentations, copy-writing
            and leave-behinds. Client communications, liaison, and demonstrations. Risk planning and opportunity
            tracking.
          </p>

          <p>
            <strong>Operations & Business Admin:</strong> Ad hoc BizDev duties for special campaigns. Research and
            investigation for strategic planning efforts. General office and on-site operations duties. Cross coverage
            for COO. Business number reporting.
          </p>

          <h3>Black Pixel &bull; [Remote] &bull; 2011&mdash;2016</h3>
          <p>
            <strong>Labs Team Quality Leadership:</strong> Served as quality lead for in-house macOS, iOS, tvOS
            applications. Shipped major releases of Kaleidoscope, NetNewsWire, Versions as well as adjacent web
            applications and in-house developer tools as sole tester. Performed both black-box and white-box methods
            focusing on functional, performance, regression and usability feature testing. Wrote and maintained test
            strategy plans. Utilized many quality assurance tactics including session-based testing, state modeling,
            equivalence class partitioning, and pairing with developers.
          </p>
          <p>
            <strong>Client Services Test Lead:</strong> Functioned as lead tester on iOS, macOS, web and, Android client
            projects both on-site and remote. Worked in a lightweight, agile workflow environment. Performed QA services
            based on client needs, from leading a projects backlog in a risk-driven development style via high-level
            engineering-integrated exploratory functional testing, all the way down to simple specification checking and
            happy path confirmations. Created test environs and contrived test data sets. Responsible for establishing
            and implementing test coverage strategy. Handcrafted many thousands of actionable defect reports. Used tools
            like UIAutomation, Charles Proxy, Docker, Postman, Instruments, Xcode, Android Studio, FogBugz, Trello, and
            Jira.
          </p>
          <p>
            <strong>Customer Support Team Lead:</strong> Managed external customer support queue for our product team.
            Answered thousands of user questions, from handling sales of our products to handling the underlying
            technology behind our products such as DVCS, networking, authentication, whatever it took to help the
            customer solve their problem. Wrote customer-facing application documentation, support desk copy, training
            guides, and knowledge base documents. Trained, hired, managed and coached Support team.
          </p>
          <p>
            <strong>Operations &amp; Infrastructure Group:</strong> Systems administration for internal IT operations
            team. team. Handled on-boarding, network admin, user management and general tech support. Handled varying
            coverage per project needs in: build engineering, release process, VCS management, beta test management,
            continuous integration setup, backups. Wrote and maintained numerous in-house technical documents for the
            team, including setting the policies and procedures therein.
          </p>

          <h3>Contagion Releasing &bull; Seattle, WA &bull; 2010&mdash;2014</h3>

          <p>
            <strong>Co-Founder:</strong> Handled all business operations for independent record label and international
            mail order. Project management. Marketing. Client liaison. Strategy. Shipping. Accounting.
          </p>

          <h3>Home Alive 501c3 &bull; Seattle, WA &bull; 2000&mdash;2001</h3>

          <p>
            <strong>Information Systems Management:</strong> Managed small office network, backups, user support,
            website maintenance, and database development.
          </p>
          <p>
            <strong>Bookkeeping:</strong> Performed all bookkeeping duties: accounts payable, payroll, bank deposits,
            account reconciliation, excise taxes, closing, general ledger entries, P&amp;L reports, audit preparations.
            Handled donor year-end tax processing.
          </p>

          <h3>Index Newspapers &bull; [Portland, OR &amp; Seattle, WA] &bull; 1999&mdash;2011</h3>

          <p>
            <strong>Systems Administration Lead:</strong> Department manager. On-call 24/7. Administered physical
            network &amp; network services. Vendor selection and liaison. Internal IT project management. Database
            server server management, user support, maintenance and, ongoing development. Wrangled fleet of servers
            spanning two offices. Maintained backup schema and security policies. Workstation support for escalated
            issues chiefly in OS X environs.
          </p>
          <p>
            <strong>Systems Developer:</strong> Back-end developer and front-end designer for burgeoning subsidiaries'
            in-house applications handling auditing, order fulfillment, and print-ready documents. Collaboration with
            web development and accounting teams to design and implement new features and reports for in-house sales and
            order systems. Known for streamlining workflows and creating solutions to increase efficiency. Automated
            countless duties of the various in-house department functions, reducing staffing needs. Handled own
            technical support queue for in-house systems.
          </p>
          <p>
            <strong>Accounting &amp; Administration:</strong> Supervised accounts team &amp; cash office, directly
            reporting to owners. Interview, hired, trained and reviewed sub-department. Known for developing and
            executing innovative departmental policies and procedures. Managed account base of long-term, large, high
            revenue and difficult clientele. Facilitated departmental inquiries concerning cost variances or
            discrepancies through intra-system research, performing credit analysis, AR/Cash projections. Assistance to
            Controller: AP, GL entries, payroll, account reconciliation and auditing projects, check writing, petty
            cash. Moderation of complex spreadsheet matrix cataloging &amp; analyzing sales data. Ad-hoc support for
            Sales/Marketing, Promotions, HR departments.
          </p>

          <h2>Education & Coursework</h2>
          <ul>
            <li>
              <strong>2016</strong> University of Washington Continuing Education
            </li>
            <li>
              <strong>2014</strong> School of Visual Concepts
            </li>
            <li>
              <strong>2001</strong> Seattle Central College
            </li>
          </ul>
          <div className="email">
            <a>shereemaria@gmail.com</a>
          </div>
        </div>
      </Content>
    </Wrapper>
  </Layout>
)

export default Resume
